
import Naslovnica from "./Naslovnica";
import Showcase from "./Showcase";
import BadgeHolder from "./BadgeHolder";
import Testemonial from "./Testemonial";
import TestemonialsHolder from "./TestemonialsHolder";
import Donacija from "./Donacija";

export default function Home() {
    var imageFirst = true;
    function alternateImagePosition() {
        imageFirst = !imageFirst;
        return imageFirst;
    }

    return (
        <>
            <Naslovnica />

            <Showcase naslov="Intuitivne i moderne aplikacije" opis="Aplikacije napravljene s pažnjom, kako bi Vaš rad bio brži i lakši" pictureFirst={alternateImagePosition()} image="bg-showcase-2.jpg" />

            <Showcase naslov="Poznavanje procesa" opis="Detaljno poznavanje radnog procea osnovni je preduvjet za stvaranje kvalitetnih aplikacija" pictureFirst={alternateImagePosition()} image="workflow.jpg" />

            <Showcase naslov="Pouzdanost u radu" opis="Zdravstvene ustanove zahtijevaju 24h rad i podršku koja mora biti isto tako dostupna 24h!" pictureFirst={alternateImagePosition()} image="reliability3.jpg" />


            <Showcase naslov="Jednostavnost i intuitivnost" opis="Ako rješenje nije jednostavno i lako shvatnjivo svakom korisniku, nije dobro učinjeno. Jednostavnost i intuitivnost prva su odlika naših tehnoloških rješenja!" pictureFirst={alternateImagePosition()} image="simplicity.jpg" />

            <BadgeHolder title="Naši informatički proizvodi" skip="---" />

            <TestemonialsHolder title="Što o našim proizvodima kažu korisnici..." />
            <Donacija />

        </>

    );
}