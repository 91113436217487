import Naslovnica from "./Naslovnica"
import Showcase from "./Showcase"

export default function Privatnost(props) {

    return (
        <>
            <Naslovnica />
            <Showcase naslov="Privatnost" opis="Zaštita osobnih podataka korisnika je jedno od temeljnih ljudskih prava, čiju zaštitu jamče odredbe Povelje Europske unije. Zaštita osobnih podataka najviši je prioritet informatičkih proizvoda." pictureFirst={false} image="privacy.jpg" />

            <Showcase naslov="GDPR" opis="Tvrtka Veos Dom štiti osobne podatke najvišim sigurnosnim standardima, kodiranjem sigurnosim protokolima i enkripcijom podataka. Poslovanje tvrtke sukladno je Općoj uredbi o zaštiti podataka (tzv. GDPR-u)." pictureFirst={true} image="gdpr.jpg" />


          
        </>
    )

}