import Badge from "./Badge";

export default function BadgeHolder(props) {

    const content = {
        badge: [{
            naslov: "SPP",
            opis: "Sustav prijema pacijenata je po mišljenju većine liječnika, medicinskih sestara i administratora najbolji bolnički informatički sustav u Hrvatskoj",
            icon: "bi-h-square",
            link: "/SPP",
            key: 1
        },
        {
            naslov: "Registar bolesnika",
            opis: "Registar bolesnika omogućava prikupljanje i obradu medicinskih podataka",
            icon: "bi-people",
            link: "/Registar",
            key: 2
        },
        {
            naslov: "SMS gateway",
            opis: "Uređaj i API za razmjenu SMS poruka",
            icon: "bi bi-envelope-plus",
            link: "/SMSgateway",
            key: 2
        },
        {
            naslov: "DL2",
            opis: "Registrator prisutnosti na radu sustav evidencije ulazaka i izlazaka pomoću beskontaktnih (RFID) kartica",
            icon: "bi-wifi",
            link: "/DL2",
            key: 3
        }
            ,
        {
            naslov: "GR1",
            opis: "Registrator prisutnosti na radu sustav evidencije ulazaka i izlazaka pomoću beskontaktnih i kontaktnih ''smart'' kartica",
            icon: "bi-person-vcard",
            link: "/GR1",
            key: 4
        }       
        ]
    }

    const badges = content.badge.map((badge) => {
        if (badge.naslov.startsWith(props.skip)) return null;
        return <Badge icon={badge.icon} naslov={badge.naslov} opis={badge.opis} key={badge.key} link={badge.link}/>

    })

    return (
        <section className="features-icons bg-light text-center">
            <div className="container">
                <div className="row">
                    <h1>{props.title}</h1>
                    {badges}
                </div>
            </div>
        </section>
    )
}