

export default function Navbar() {
    return (
        <nav className="navbar navbar-expand-md navbar-light bg-light shadow">
       <img src="%PUBLIC_URL%/../logo.png" alt="logo" ></img><a className="navbar-brand" href="/">Veos Dom</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="nav-link" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="about">O nama</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Proizvodi
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/SPP">Bolnički informatički sustav SPP</a>
                <a className="dropdown-item" href="/Registar">Registar bolesnika RB</a>
                <a className="dropdown-item" href="/SMSGateway">SMS gateway SG-1</a>
               <a className="dropdown-item" href="/DL2">Registrator prisutnosti na radu DL2</a>
               {/*  <div className="dropdown-divider"></div> */}
                <a className="dropdown-item" href="GR1">Registrator prisutnosti na radu GR1</a>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="contact">Kontakt</a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link disabled" href="#">Disabled</a>
            </li> */}
          </ul>
          {/* <form className="form-inline my-2 my-lg-0">
            <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
          </form> */}
        </div>
        </nav>
  
    );
  }
  

  

