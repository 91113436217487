
export default function Footer(props) {
    const linkStyle = "nav-link invert "
    return (
        <footer className="footer navbar-light bg-light">
            <div className="container">


                <div className="row ">
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 border-right">
                        <img src="logo.png" alt="logo" className="img-responsive" /><h4>Veos dom</h4>©2016-2023<br />Sva prava pridržana 
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-1 pt-1">
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 border-right">
                                <nav className="">
                                    <ul className="list-unstyled">

                                        <li><a className={linkStyle} href="about">O nama</a></li>
                                        <li><a className={linkStyle} href="partner">Suradnja</a></li>

                                    </ul>
                                </nav>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 border-right">
                                <nav className="">
                                    <ul className="list-unstyled">
                                        <li><a className={linkStyle} href="contact">Kontakt</a></li>
                                        <li><a className={linkStyle} href="support">Podrška</a></li>

                                    </ul>
                                </nav>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 border-right">
                                <nav>
                                    <ul className="list-unstyled">
                                        <li><a className={linkStyle} href="privacy">Privatnost</a></li>
                                        <li><a className={linkStyle} href="careers">Zaposlenja</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 border-right pt-2">
                        {/* <p className={linkStyle} >Slijedite nas</p>

                        <Link name="facebook" class="text-left" link="https://www.facebook.com/" />F
                        <Link name="twitter" class="float-start" link="https://www.twitter.com/" />
                        <Link name="instagram" class="float-start" link="https://www.instagram.com/" /> */}
                        <p className=" mb-0 left"><i className="bi bi-house-door"></i> Jurja Križanića 11, 10000 Zagreb</p>
                        <p className=" mb-0 left"><i className="bi bi-telephone"></i> +385 1 5620 900</p>
                        <p className=" mb-0 left"><i className="bi bi-phone-vibrate"></i> +385 91 1620 904</p>
                        <p className=" mb-0 left"><i className="bi bi-envelope-at"></i> info@veos-dom.hr</p>

                    </div>

                </div>

            </div>
        </footer>
    )

    function Link(props) {
        return (
            <>
                <a target="_blank" className="nav-link invert" href={props.link} title={props.name} >
                    <img src={`img/${props.name}.png`} alt={props.name} className="img-responsive" /> {props.name}</a>
            </>
        )
    }



}