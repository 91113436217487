import Testemonial from "./Testemonial"
export default function TestemonialsHolder(props) {

    const content={
        testemonials: [{
            ime: "Nikolina M., dr.med.",
            izjava: "Ovaj bolnički informatički sustav mi omogućuje najbolji i najbrži uvid u bolesničku dokumentaciju, laboratorijske i druge nalaze!",
            image: "testimonials-1.jpg",
            key: 1
        },
        {
            ime: "Alen B., dr.med.",
            izjava: "Ovaj bolnički informatički sustav jedini u Hrvatskoj ima odlike pravog bolničkog informatičkog sustava!",
            image: "testimonials-2.jpg",
            key: 2
        },
        {
            ime: "Anita J., vms.",
            izjava: "Vrlo je jednostavno, svim potrebnim funkcijama sam ovladala u samo nekoliko sati!",
            image: "testimonials-3.jpg",
            key: 3
        },
        ]
    }

    const testemonials = content.testemonials.map((testemonial) => {
        return <Testemonial image={testemonial.image} ime={testemonial.ime} izjava={testemonial.izjava} key={testemonial.key} />
    });



    return (
        <section className="testimonials text-center bg-light">
            <div className="container">
                <h2 className="mb-5">{props.title}</h2>
                <div className="row">
                    {testemonials}
                </div>
            </div>
        </section>
    )
}