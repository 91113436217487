import Showcase from "./Showcase";
import BadgeHolder from "./BadgeHolder";
import TestemonialsHolder from "./TestemonialsHolder";





export default function Naslovnica() {
    const style = { backgroundImage: `url('/img/spp.png')` }

    let imageFirst = true;
    function alternateImagePosition() {
        imageFirst = !imageFirst;
        return imageFirst;
    }

    return (
        <>
            <header className="masthead" style={style}>
                <div className="container position-relative px-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="text-center text-white">
                                <h1 className="mb-5"> SPP</h1>
                                <h2> Sustav prijema pacijenata</h2>
                                <p>najbolji bolnički sustav u Hrvatskoj po mišljenju korisnika</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Showcase naslov="SPP" opis="SPP je cjeloviti bolnički informatički sustav sa integriranim kliničkim, radiološki, laboratorijskim, mikrobiološkim, financijskim, izvještajnim i svim drugim modulima potrebnim za rad bolnica u Hrvatskoj." pictureFirst={alternateImagePosition()} image="bis.jpg" />

            <Showcase naslov="Servisi i izvješća za Ministarstvo zdravstva RH, HZZO, HZJZ i dr." opis="SPP je cjeloviti bolnički informatički sustav koji sadržava sve potrebne servise i module za komunikaciju sa sustavima poput eNaručivanja, BSO, CUS, fakturiranja za HZZO i sva dopunske osiguravatelje i drugo." pictureFirst={alternateImagePosition()} image="health.jpg" />

            <Showcase naslov="Web bazirani sustav" opis="Sustav je u cijelosti koncipiran kao Web aplikacija, pokreće se iz preglednika Interneta, što znatno olakšava pristup i rad korisnicima" pictureFirst={alternateImagePosition()} image="naslovna.png" />

            <Showcase naslov="Integrirani radiološki sustav" opis="SPP sadržava svoj vlastiti radiološki podsustav (tzv. RIS i PACS), što omogućuje pregled radiolokših nalaza izravno iz SPP-a, bez potrebe za instalacijom drugih programa." pictureFirst={alternateImagePosition()} image="rtg.png" />

            <Showcase naslov="Integrirani laboratorijski sustav" opis="SPP sadržava svoj vlastiti laboratorijski podsustav (tzv. LIS), što omogućuje jednostavnu interakciju s laboratorijem, pregled nalaza u numeričkom i grafičkom obliku." pictureFirst={alternateImagePosition()} image="nalazi-graf.png" />

            <Showcase naslov="Integrirana sestrinska dokumentacija" opis="SPP sadržava integrirani podsustav za vođenje sestrinske dokumentacije. Sustav je certificiran od strane Ministarstva zdravstva Republike Hrvatske." pictureFirst={alternateImagePosition()} image="sestrinska-lista-dekubitus.png" />

            <Showcase naslov="Integrirani fakturni sustav" opis="SPP sadržava integrirani podsustav za potpuno i samostalno fakturiranje učinjenih usluga prema HZZO-u, posebnim klijentima, privatnim kupcima itd, kao i sva potrebna izvješća." pictureFirst={alternateImagePosition()} image="finance.jpg" />

            <Showcase naslov="Integrirani ljekarnički sustav" opis="SPP sadržava integrirani podsustav za potpuni i samostalni rad ljekarne, sa svim potrebnim izvješćima. " pictureFirst={alternateImagePosition()} image="pills.jpg" />

            <Showcase naslov="Integrirani sustav bolničke kuhinje" opis="SPP sadržava integrirani podsustav za potpuni rad bolničke kuhinje, izračun troškovnika prema jelovniku itd. sa svim potrebnim izvješćima. " pictureFirst={alternateImagePosition()} image="kuhinja.jpg" />

            <TestemonialsHolder title="Što o SPP-u kažu korisnici..." />

            <Showcase naslov="Jednostavan i intuitivan rad" opis="SPP pruža najbolju podršku radnom procesu bolnica, omogućavajući najbrži i najlakši rad, s najmanje &quot;klikova&quot; mišem." pictureFirst={alternateImagePosition()} image="hospital-work2.jpg" />

            <Showcase naslov="Potpomognuto MKB šifriranje" opis="MKB šifriranje dijagnoza neizostavan je dio radnog procesa bolničkih liječnika. SPP olakšava šifriranje prijedlogom svih dijagnoza odjednom, jednim klikom miša. " pictureFirst={alternateImagePosition()} image="auto-mkb.png" />

            <Showcase naslov="Mobilna aplikacija" opis="SPP nativna aplikmacija za Android mobilne uređaje omogućava pregled nalaza na mobitelu, u svakom trenutku, u pokretu i uz krevet bolesnika. Aplikacija je besplatna i dostupna na Google Play Store-u." pictureFirst={alternateImagePosition()} image="mobile.jpg" />

            <Showcase naslov="SMS komunikacija i obavještavanje bolesnika" opis="SPP omogućava neograničenu i besplatnu razmjenu SMS poruka između korisnika i prema bolesnicima, kao i podsjetnike bolesnicima na predstojeće dijagnostičke i terapijske postupke." pictureFirst={alternateImagePosition()} image="sms.jpg" />

            <Showcase naslov="SPP sustavi bolnica su međusobno povezani" opis="Međusobni dohvat bolesničke dokumentacije i nalaza između bolnica koje imaju SPP moguć je automatski (tzv. 'out-of-the-box'), bez potrebe za dodatnim ulaganjima. Dohvat je kontroliran, zaštićen, kriptiran i moguć isključivo i samo u periodu boravka bolesnika unutar bolnice." pictureFirst={alternateImagePosition()} image="network.jpg" />

            <Showcase naslov="4-10x manji troškovi IT resursa u usporedbi sa drugim bolničkim informatičkim sustavima (BIS) u Hrvatskoj" opis="Dizajn i tehnologij SPP sustava omogućava uštede informatičkih resursa (serveri, memorija, hlađenje...) i do 10X u usporedbi sa drugim bolničkim informatičkim sustavima u Hrvatskoj." pictureFirst={alternateImagePosition()} image="savings2.jpg" />

            <Showcase naslov="Pozadinsko slanje eNalaza u CEZIH" opis="SPP omogućuje obavljanje raznih zadataka bez ometanja liječnika, npr. pozadinskim slanjem zaključenih nalaza u CEZIH." pictureFirst={alternateImagePosition()} image="e-nalaz.jpg" />

            <BadgeHolder title="Naši ostali informatički proizvodi" skip="SPP" />


        </>

    )
}