export default function Naslovnica() {
    const style = { backgroundImage: `url('/img/bg-desk.jpg')` }

    return (
        <header className="masthead" style={style}>
            <div className="container position-relative px-5">
                <div className="row justify-content-center">
                    <div className="text-center text-white text-shadow">
                        <h1 className="mb-5">Veos dom</h1>
                        <h2 > za zdravstvenu informatiku</h2>
                        <span >doprinos zdravstvenoj informatici i njenoj primjeni u liječenju bolesnika.</span>
                    </div>
                </div>
            </div>
        </header>

    )
}