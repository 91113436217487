import Showcase from "./Showcase";
import BadgeHolder from "./BadgeHolder";


export default function Registar() {
    const style = { backgroundImage: `url('/img/rbb.png')` }
   
    var imageFirst = true;
    function alternateImagePosition() {
        imageFirst = !imageFirst;
        return imageFirst;
    }

    return (
        <>
            <header className="masthead" style={style}>
                <div className="container position-relative px-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="text-center text-white">

                                <h1 className="mb-5">RB</h1>
                                <h2>Registar bolesnika</h2>
                                <p>informatički proizvod koji omogućava centralizirano, sigurno i jednostavno prikupljanje medicinskih podataka.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <Showcase naslov="Neophodan alat u medicini 21. stoljeća" opis="Alat ppomoću kojeg dobivamo vrlo važne informacije o bolestima kao što su: incidencija, prevalencija, raspodjela po dobi, spolu, dijagnozi, trendovi i analize preživljavanja, planiranje i praćenje liječenja i drugo." pictureFirst={alternateImagePosition()} image="data.jpg" />

            <Showcase naslov="Pristup putem Interneta 24h dnevno" opis="Za korištenje sustava dovoljan je pretraživač interneta. Bez instalacije programske podrške, uz zaštićen pristup, korištenjem “pametnih” kartica (HZZO, HLZ, osobna iskaznica…)." pictureFirst={alternateImagePosition()} image="rbb-computer.jpg" />

            <Showcase naslov="Jednostavno i lako ažuriranje podataka" opis="Sustav omogućuje istovremeno ažuriranje podatka od strane više ovlaštenih korisnika. Broj korisnika nije ograničen.  " pictureFirst={alternateImagePosition()} image="rbb2.png" />

            <Showcase naslov="Najviši standardi sigurnosti i enkripcije podataka" opis="Registru mogu pristupati isključivo ovlašteni korisnici, autorizirani tzv. &quot;smart&Quot; karticom. Podaci su zaštićeni najsuvremenijim sigurnosnim standardima i enkripcijom." pictureFirst={alternateImagePosition()} image="gdpr2.jpg" />

            <Showcase naslov="Smještaj podataka u podatkovnom centru na teritoriju Republike Hrvatske" opis="Sustav je smješten na poslužiteljima unutar podatkovnog centra na teritoriju Republike Hrvatske, sukladno važećim zakonskim propisima o smještaju naročito osjetljivih podataka." pictureFirst={alternateImagePosition()} image="croatia.jpg" />

            <Showcase naslov="Sukladnost sa općom uredbom o zaštiti osobnih podataka (GDPR)" opis="Zaštita osobnih podataka korisnika je jedno od temeljnih ljudskih prava, čiju zaštitu jamče odredbe Povelje Europske unije. Zaštita osobnih podataka najviši je prioritet informatičkih proizvoda." pictureFirst={alternateImagePosition()} image="gdpr.jpg" />
           
            <Showcase naslov="Pomoć u radu" opis="Tvrtka Veos Dom osigurava punu podršku korisnicima registra unutar radnog vremena." pictureFirst={alternateImagePosition()} image="support.jpg" />
          

            <BadgeHolder title="Naši ostali informatički proizvodi" skip="Registar" />

        </>

    )
}