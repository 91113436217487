import Naslovnica from "./Naslovnica"
import Showcase from "./Showcase"

export default function About(props) {

    return (
        <>
            <Naslovnica />
            <Showcase naslov="O nama" opis="Tvrtka Veos Dom osnovana je 2017. godine s ciljem doprinosa zdravstvenoj informatici i njenoj primjeni u liječenju. Nastavak je djelovanja tvrtke Astramedika, osnovane 2008. godine." pictureFirst={false} image="company.jpg" />

            <Showcase naslov="Naše usluge" opis="Naše usluge vezane su za pružanje potpore radu zdravstvenih ustanova kroz zdravstvenu informatiku, mobilne aplikacije, održavanje baza podataka, održavanje poslovnih sustava i drugo." pictureFirst={true} image="database.webp" />


          
        </>
    )

}