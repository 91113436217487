export default function Donacija() {
    const style = { backgroundImage: `url('/img/tree-desktop-bg.jpg')` }

    return (
        <header className="masthead" style={style}>
            <div className="container position-relative">
                <div className="row justify-content-center">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                        <div className="text-right ">
                            <h4 className="text-white">Donirajmo zajedno</h4>
                            <h5 className="text-white">Za svaku novi kupljeni proizvod od 2022. godine doniramo 1% vrijednosti za izgradnju dječjeg sela.</h5>
                            {/* <button className="btn btn-primary">više o ovome</button> */}
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6"></div>
                </div>
            </div>
        </header>
    )
}