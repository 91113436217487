import Showcase from "./Showcase";
import BadgeHolder from "./BadgeHolder";


export default function GR1() {
    const style = { backgroundImage: `url('/img/gr1-header.png')` }

    return (
        <>
            <header className="masthead" style={style}>
                <div className="container position-relative px-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="text-center text-white">
                                <h1 className="mb-5">GR1</h1>
                                <h2>Registrator prisutnosti na radu</h2>
                                <p>sustav za registraciju radnog vremena djelatnika korištenjem "smart" kartica i zdravstvenih iskaznica</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Showcase naslov="Registracija prisutnosti na radu" opis="Sustavi registracije radnog vremena neopdhodni su za funkcioniranje svake velike ustanove sa puno zaposlenika, pa tako i bolnica. Bolničku organizaciju rada karakterizira više modaliteta rada kao što su to npr. redovni rad, smjenski rad, dežurstva itd. " pictureFirst={true} image="smart-card.jpg" />

            <Showcase naslov="Sustav GR1" opis="Sustav se sastoji od kompjuterskog terminala opremljenog čitačem kartica (kontaktnim i/ili beskontaktnim) i ekrana osjetljivog na dodir. Za rad terminala potreban je priključak na kompjutersku i strujnu mrežu. Ukoliko je u blizini dostupan pouzdan Wifi signal, može se koristiti umjesto žičane veze. Također je moguće terminal opremiti mogućnošću GSM povezivanja za udaljene lokacije. " pictureFirst={false} image="gr1.png" />

            <Showcase naslov="Izdržljivo kućište" opis="Kućište uređaja izrađeno je od čvrstih materijala i omogućava postavljanje na udaljenim i nenadziranim lokacijama. " pictureFirst={true} image="hammer.jpg" />

            <Showcase naslov="Ugrađena kamera" opis="Uređaj je opremljen kamerom i detekcijom udarca i trešnje kako bi se spiječila zlouporaba." pictureFirst={false} image="camera.jpg" />

            <Showcase naslov="Nadzor rada uređaja" opis="U slučaju kvara ili gubitka veze termminala sa centralnim poslužiteljem, centralni sustav administratora obavještava SMS porukom." pictureFirst={true} image="sms.jpg" />

            <Showcase naslov="Izvješća o prisutnosti na radu" opis="Sustav periodički šalje izvješća o prisutnosti na radu, u pdf obliku ili excel tablici. Posebno izvjšće kreira se za potrebe zdravstvenih ustanova" pictureFirst={false} image="reports.jpg" />

            <BadgeHolder title="Naši ostali informatički proizvodi" skip="GR1" />

        </>

    )
}