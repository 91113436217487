export default function Testemonial(props) {
    return (
        <div className="col-lg-4">
        <div className="testimonial-item mx-auto mb-5 mb-lg-0">
            <img className="img-fluid rounded-circle mb-3" src={`/img/${props.image}`} alt="..." />
            <h5>{props.ime}</h5>
            <h5 className="font-weight-light mb-0">{props.izjava}</h5>
        </div>
    </div>
    )
}