import Showcase from "./Showcase";
import BadgeHolder from "./BadgeHolder";


export default function SMSGateway() {
    const style = { backgroundImage: `url('/img/SG1_background.png')` }

    let imageFirst = true;
    function alternateImagePosition() {
        imageFirst = !imageFirst;
        return imageFirst;
    }

    return (
        <>
            <header className="masthead" style={style}>
                <div className="container position-relative px-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="text-center text-white">
                                <h1 className="mb-5">SMS Gateway</h1>
                                <h2>Uređaj i API za razmjenu SMS poruka</h2>
                                <p>Obogatite svoj poslovni sustav ili drugu aplikaciju mogućnošću razmjene (praktički) neograničenog broja SMS poruka</p>
                            </div>
                        </div>
                    </div>
                </div>


            </header>
            <Showcase naslov="Jednostavan i kompaktan uređaj" opis="Naš SMS gateway je idealan za tvrtke, organizacije i pojedince koji žele poboljšati svoju komunikaciju putem SMS-a." pictureFirst={alternateImagePosition()} image="SG1_1b.png" />
            <Showcase naslov="SMS komunikacija" opis="U današnjem digitalnom svijetu, SMS je i dalje jedan od najpopularnijih načina komunikacije. Ako tražite jednostavan i učinkovit način za slanje i primanje SMS-ova, naš SMS gateway je savršeno rješenje za vas" pictureFirst={alternateImagePosition()} image="sms2.jpg" />

            <Showcase naslov="API" opis="Jednostavan API (aplikacijsko programsko sučelje) dizajnirano kako bi bilo što jednostavnije za korištenje. Možete ga koristiti za slanje SMS-ova pojedincima ili grupama, kao i za primanje SMS-ova i obradu u realnom vremenu.

" pictureFirst={alternateImagePosition()} image="bg-showcase-2.jpg" />

            <Showcase naslov="Fiksni mjesečni troškovi" opis="Broj SMS poruka koji se prima i šalje nije ograničen. Na taj način možete planirati svoje troškove" pictureFirst={alternateImagePosition()} image="finance.jpg" />

            <Showcase naslov="Uštedite" opis="Slična rješenja koštaju višestruko više! " pictureFirst={alternateImagePosition()} image="savings3.jpg" />

            <Showcase naslov="Vaša (ili naša) SIM kartica" opis="Uređaj sadrži Vašu SMS karticu s brojem unutar ranga Vaše tvrtke. Na taj način ste prepoznatljivi klijentima i korisnicima" pictureFirst={alternateImagePosition()} image="sim.jpg" />

            <Showcase naslov="Jednostavno povezivanje" opis="Uređaj se povezuje na internet i Vaš poslvni sustav putem ugrađenog, konfigurabilnog WiFi sučelja" pictureFirst={alternateImagePosition()} image="wifi.jpg" />

            <Showcase naslov="Naš API je prilagodljiv" opis="API se može prilagoditi potrebama i posebnostima Vašeg sustava" pictureFirst={alternateImagePosition()} image="B2B.webp" />
          
            <Showcase naslov="Kompletno rješenje" opis="Za svoj rad uređaj ne zahtijeva nikakav dodatni kompjuter ili druge uređaje. Povezuje se na Vaš sustav putem HTTPS upita. Sadržaj komunikacije je zaštićen" pictureFirst={alternateImagePosition()} image="people.jpg" />

            <BadgeHolder title="Naši ostali informatički proizvodi" skip="SMS gateway" />

        </>

    )
}