import Showcase from "./Showcase";
import BadgeHolder from "./BadgeHolder";


export default function DL2() {
    const style = { backgroundImage: `url('/img/dl2-b.png')` }
   
    let imageFirst = true;
    function alternateImagePosition() {
        imageFirst = !imageFirst;
        return imageFirst;
    }
  
    return (
        <>
            <header className="masthead" style={style}>
                <div className="container position-relative px-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="text-center text-white">
                                <h1 className="mb-5">DL2</h1>
                                <h2>Registrator prisutnosti na radu</h2>
                                <p>sustav za registraciju radnog vremena djelatnika korištenjem NFC beskontaktnih kartica</p>
                            </div>
                        </div>
                    </div>
                </div>


            </header>
            <Showcase naslov="Prijava dolaska i odlaska NFC karticom" opis="Sustav registracije prisutnosti na radu korištenjem DL2 uređaja i beskontaktnih NFC kartica omogućava toćno, pouzdano i brzo bilježenje dolazaka i odlazaka, bez stvaranja jutarnjih i popodnevnih gužvi." pictureFirst={alternateImagePosition()} image="nfc-card.jpg" />

            <Showcase naslov="DL2 uređaji" opis="DL2 uređaji rade u paru, jedan za bilježenje dolazaka, drugi za bilježenje odlazaka. Time se u potpunosti isključuje potreba interakcije s uređajem, prijava je jednostavna je i traje samo nekoliko sekundi. " pictureFirst={alternateImagePosition()} image="dl2.png" />

            <Showcase naslov="Moguće korištenje raznih formata NFC oznaka" opis="Moguće je koristiti NFC oznake u obliku kartice, privjeska ili čak male naljepnice." pictureFirst={alternateImagePosition()} image="nfc-tags.jpg" />

            <Showcase naslov="Jednostavna povezivost" opis="DL2 uređaji se s serverom povezuju pomoću Wi-Fi mreže, što čini njihovu instalaciju izrazito jednostavnom. " pictureFirst={alternateImagePosition()} image="wifi.jpg" />

            <Showcase naslov="Jednostavna montaža" opis="DL2 uređaji se može postaviti na zid pomoću 2 vijka. Nacrt i šablona za montažu su priloženi su uređajem.  " pictureFirst={alternateImagePosition()} image="dl2-mounted.png" />

            <Showcase naslov="Izvješća o prisutnosti na radu" opis="Sustav periodički šalje izvješća o prisutnosti na radu, u pdf obliku ili excel tablici. Posebno izvješće kreira se za potrebe zdravstvenih ustanova" pictureFirst={alternateImagePosition()} image="reports.jpg" />

            <BadgeHolder title="Naši ostali informatički proizvodi" skip="DL2" />

        </>

    )
}