import Naslovnica from "./Naslovnica"
import Showcase from "./Showcase"

export default function Partner(props) {

    return (
        <>
            <Naslovnica />
            <Showcase naslov="Suradnja" opis="Tvrtka Veos Dom otvorena je za suradnju sa partnerskim tvrtkama. Za mogućnosti suradnje kroz zajedničku izadu aplikacija ili promoviranje naših proizvoda izvan Hrvatske, kontaktirajte nas. " pictureFirst={false} image="partner.jpg" />

        

          
        </>
    )

}